import { combineReducers } from "redux";
import {
  createWorkActivitiesReducer,
  updateWorkActivityReducer,
  fetchWorkActivitiesReducer,
  updateWorkActivityModalStatusReducer,
  editWorkActivityReducer,
  newWorkActivityReducer,
  deleteWorkActivityReducer,
} from "./WorkActivities/WorkActivitiesReducer";

import {
  createCoursesReducer,
  updateCourseReducer,
  fetchCoursesReducer,
  updateCourseModalStatusReducer,
  editCourseReducer,
  newCourseReducer,
  deleteCourseReducer,
} from "./Courses/CoursesReducer";

import {
  createMcatScoresReducer,
  updateMcatScoreReducer,
  fetchMcatScoresReducer,
  updateMcatScoreModalStatusReducer,
  editMcatScoreReducer,
  newMcatScoreReducer,
  deleteMcatScoreReducer,
} from "./McatScores/McatScoresReducer";

import {
  loginAuthReducer,
  adminUserReducer,
  renewTokenReducer,
} from "./UserAuthentication/LoginReducer";

import { fetchGradeReducer } from "./Grades/GradesReducer";

import { signupUserReducer } from "./UserAuthentication/RegisterReducer";
import {
  updateForgotPasswordModalStatusReducer,
  forgotPasswordReducer,
} from "./UserAuthentication/ForgotPasswordReducer";
import { resetPasswordReducer } from "./UserAuthentication/ResetPasswordReducer";

import {
  personalStatementReducer,
  fetchPersonalStatementReducer,
} from "./PersonalStatement/PersonalStatementReducer";

import { fetchSchoolSearchReducer } from "./SchoolSearch/SchoolSearchReducer";
import {
  createSelectSchoolForSecondaryEssaysReducer,
  fetchSelectSchoolForSecondaryEssaysReducer,
  fetchResponseForSecondaryEssaysReducer,
  createAnswersForSecondaryEssaysReducer,
  deleteSelectedSchoolForSecondaryEssayReducer,
  deleteResponseForSecondaryEssayReducer,
  updateSecondaryStatusReducer,
} from "./SecondaryEssays/SecondaryEssaysReducer";

import {
  createProfilePictureReducer,
  updateUserProfileReducer,
  fetchUniversitiesReducer,
  getUserDetailsReducer,
  updateUserPhoneNumberReducer,
  updateUserCommunitySettingsReducer,
} from "./Users/UsersReducer";
import { fetchSSOTokenReducer } from "./Ideas/IdeasReducer";
import {
  fetchAllUsersReducer,
  fetchUserDetailsReducer,
  updateUserDetailsReducer,
  updateMultiUsersReducer,
} from "./ManageUsers/ManageUsersReducer";
import {
  createCustomerReducer,
  createSubscriptionReducer,
  updateSubscriptionReducer,
  cancelSubscriptionReducer,
  manageSubscriptionModalStatusReducer,
  resumeSubscriptionReducer,
  fetchDetailsSubscriptionReducer,
  buyTutoringHoursModalStatusReducer,
  createTutoringHoursPaymentReducer,
  createTpcCoursePaymentReducer,
  fetchPaymentRecordReducer,
  updateTutoringHoursAvailableReducer,
} from "./Payments/PaymentsReducer";
import { createReportedIssueReducer } from "./Support/SupportReducer";
import {
  createCoachTutorAvailabilityReducer,
  fetchCoachTutorAvailabilityReducer,
  fetchHistoryCoachTutorAvailabilityReducer,
  deleteCoachTutorAvailabilityReducer,
} from "./ManageCoachTutorAvailability/ManageCoachTutorAvailabilityReducer";

import {
  fetchAvailableScheduleReducer,
  fetchScheduleReducer,
  createRequestTutorReducer,
  createRequestSchedTutorReducer,
  fetchRequestTutorReducer,
  fetchStudentScheduleReducer,
  cancelRequestTutorReducer,
  fetchRequestTutorHistoryReducer,
  fetchCoachesReducer,
} from "./ScheduleTutor/ScheduleTutorReducer";

import {
  fetchStudentRequestTutorReducer,
  approveStudentRequestTutorReducer,
  disapproveStudentRequestTutorReducer,
  fetchStudentRequestTutorHistoryReducer,
  updateModalUploadTutorVideoFileStatusReducer,
  editTutorRequestReducer,
  createStrUploadReducer,
} from "./ManageTutorRequests/ManageTutorRequestsReducer";

import {
  createProMemberPostReducer,
  updateCommunityProMemberPostReducer,
  deleteCommunityProMemberPostReducer,
  createCommunityProMemberCommentReducer,
  updateCommunityProMemberCommentReducer,
  deleteCommunityProMemberCommentReducer,
  createCommunityProMemberReplyReducer,
  updateCommunityProMemberReplyReducer,
  deleteCommunityProMemberReplyReducer,
} from "./Community/ProMemberReducer";

import {
  createUasPostReducer, 
  updateCommunityUasPostReducer,
  deleteCommunityUasPostReducer,
  createCommunityUasCommentReducer,
  updateCommunityUasCommentReducer,
  deleteCommunityUasCommentReducer,
  createCommunityUasReplyReducer,
  updateCommunityUasReplyReducer,
  deleteCommunityUasReplyReducer,} from "./Community/UasReducer"

import {
  fetchAllCommunityPostsReducer,
  createCommunityPostReducer,
  updateCommunityPostReducer,
  deleteCommunityPostReducer,
  createCommunityCommentReducer,
  updateCommunityCommentReducer,
  deleteCommunityCommentReducer,
  createCommunityReplyReducer,
  updateCommunityReplyReducer,
  deleteCommunityReplyReducer,
  fetchCommunityLikedPostReducer,
  fetchLeaderboardReducer,
} from "./Community/CommunityReducer";

import {
  createTpcCourseReducer,
  updateTpcCourseReducer,
  fetchTpcCoursesReducer,
  deleteTpcCourseReducer,
  createTpcCourseCategoryReducer,
  createTpcCourseLessonsReducer,
  updateTpcCourseCategoryOrderReducer,
  updateTpcCourseCategoryReducer,
  updateTpcCourseLessonsReducer,
  deleteTpcCourseLessonsReducer,
  deleteTpcCourseCategoryReducer,
  updateMarkedCompleteLessonReducer,
  fetchMarkedCompleteLessonReducer,
  createBundleCoursesReducer,
  fetchBundleCoursesReducer,
} from "./ManageTpcCourses/ManageTpcCoursesReducer";

import { fetchAppHourUsedLogsReducer, fetchAppLogReducer,fetchPremiumHourLogsReducer, sendEmailApplicationHourUsedReducer } from "./Logs/LogsReducer";

import {sendEmailNotificationReducer} from "./Community/EmailReducer";

const rootReducer = combineReducers({
  createWorkActivities: createWorkActivitiesReducer,
  updateWorkActivities: updateWorkActivityReducer,
  fetchWorkActivities: fetchWorkActivitiesReducer,
  updateWorkActivityModalStatus: updateWorkActivityModalStatusReducer,
  editWorkActivity: editWorkActivityReducer,
  newWorkActivity: newWorkActivityReducer,
  deleteWorkActivity: deleteWorkActivityReducer,

  createCourses: createCoursesReducer,
  updateCourses: updateCourseReducer,
  fetchCourses: fetchCoursesReducer,
  updateCourseModalStatus: updateCourseModalStatusReducer,
  editCourse: editCourseReducer,
  newCourse: newCourseReducer,
  deleteCourse: deleteCourseReducer,

  createMcatScores: createMcatScoresReducer,
  updateMcatScores: updateMcatScoreReducer,
  fetchMcatScores: fetchMcatScoresReducer,
  updateMcatScoreModalStatus: updateMcatScoreModalStatusReducer,
  editMcatScore: editMcatScoreReducer,
  newMcatScore: newMcatScoreReducer,
  deleteMcatScore: deleteMcatScoreReducer,

  loginAuth: loginAuthReducer,
  fetchGrade: fetchGradeReducer,
  signupUser: signupUserReducer,

  updateForgotPasswordModalStatus: updateForgotPasswordModalStatusReducer,
  forgotPassword: forgotPasswordReducer,

  resetPassword: resetPasswordReducer,
  personalStatement: personalStatementReducer,
  fetchPersonalStatement: fetchPersonalStatementReducer,

  fetchSchoolSearch: fetchSchoolSearchReducer,

  createSelectSchoolForSecondaryEssays:
    createSelectSchoolForSecondaryEssaysReducer,
  fetchSelectSchoolForSecondaryEssays:
    fetchSelectSchoolForSecondaryEssaysReducer,
  fetchResponseForSecondaryEssays: fetchResponseForSecondaryEssaysReducer,
  createAnswersForSecondaryEssays: createAnswersForSecondaryEssaysReducer,
  deleteSelectedSchoolForSecondaryEssay:
    deleteSelectedSchoolForSecondaryEssayReducer,
  deleteResponseForSecondaryEssay: deleteResponseForSecondaryEssayReducer,
  updateSecondaryStatus: updateSecondaryStatusReducer,

  createProfilePicture: createProfilePictureReducer,
  updateUserProfile: updateUserProfileReducer,
  updateUserCommunitySettings: updateUserCommunitySettingsReducer,
  fetchUniversities: fetchUniversitiesReducer,
  fetchSSOToken: fetchSSOTokenReducer,
  fetchAllUsers: fetchAllUsersReducer,
  fetchUserDetails: fetchUserDetailsReducer,
  updateUserDetails: updateUserDetailsReducer,
  updateMultiUsers: updateMultiUsersReducer,
  createCustomer: createCustomerReducer,
  createSubscription: createSubscriptionReducer,
  updateSubscription: updateSubscriptionReducer,
  cancelSubscription: cancelSubscriptionReducer,
  manageSubscriptionModalStatus: manageSubscriptionModalStatusReducer,
  resumeSubscription: resumeSubscriptionReducer,
  fetchDetailsSubscription: fetchDetailsSubscriptionReducer,
  buyTutoringHoursModalStatus: buyTutoringHoursModalStatusReducer,
  createTutoringHoursPayment: createTutoringHoursPaymentReducer,
  createTpcCoursePayment: createTpcCoursePaymentReducer,
  fetchPaymentRecord: fetchPaymentRecordReducer,
  updateTutoringHoursAvailable: updateTutoringHoursAvailableReducer,
  createReportedIssue: createReportedIssueReducer,
  adminUser: adminUserReducer,
  renewToken: renewTokenReducer,

  updateUserPhoneNumber: updateUserPhoneNumberReducer,
  getUserDetails: getUserDetailsReducer,
  createCoachTutorAvailability: createCoachTutorAvailabilityReducer,
  fetchCoachTutorAvailability: fetchCoachTutorAvailabilityReducer,
  fetchHistoryCoachTutorAvailability: fetchHistoryCoachTutorAvailabilityReducer,
  deleteCoachTutorAvailability: deleteCoachTutorAvailabilityReducer,

  fetchAvailableSchedule: fetchAvailableScheduleReducer,
  fetchSchedule : fetchScheduleReducer,
  createRequestTutor: createRequestTutorReducer,
  createRequestSchedTutor: createRequestSchedTutorReducer,
  fetchRequestTutor: fetchRequestTutorReducer,
  fetchStudentSchedule: fetchStudentScheduleReducer,
  cancelRequestTutor: cancelRequestTutorReducer,
  fetchStudentRequestTutor: fetchStudentRequestTutorReducer,
  approveStudentRequestTutor: approveStudentRequestTutorReducer,
  disapproveStudentRequestTutor: disapproveStudentRequestTutorReducer,
  fetchRequestTutorHistory: fetchRequestTutorHistoryReducer,
  fetchStudentRequestTutorHistory: fetchStudentRequestTutorHistoryReducer,
  updateModalUploadTutorVideoFileStatus:
    updateModalUploadTutorVideoFileStatusReducer,
  editTutorRequest: editTutorRequestReducer,
  createStrUpload: createStrUploadReducer,

  fetchAllCommunityPosts: fetchAllCommunityPostsReducer,
  createCommunityPost: createCommunityPostReducer,
  updateCommunityPost: updateCommunityPostReducer,
  deleteCommunityPost: deleteCommunityPostReducer,
  createCommunityComment: createCommunityCommentReducer,
  updateCommunityComment: updateCommunityCommentReducer,
  deleteCommunityComment: deleteCommunityCommentReducer,
  createCommunityReply: createCommunityReplyReducer,
  updateCommunityReply: updateCommunityReplyReducer,
  deleteCommunityReply: deleteCommunityReplyReducer,
  fetchCommunityLikedPost: fetchCommunityLikedPostReducer,
  fetchLeaderboard: fetchLeaderboardReducer,

  createProMemberPost: createProMemberPostReducer,
  updateCommunityProMemberPost: updateCommunityProMemberPostReducer,
  deleteCommunityProMemberPost: deleteCommunityProMemberPostReducer,
  createCommunityProMemberComment: createCommunityProMemberCommentReducer,
  updateCommunityProMemberComment: updateCommunityProMemberCommentReducer,
  deleteCommunityProMemberComment: deleteCommunityProMemberCommentReducer,
  createCommunityProMemberReply: createCommunityProMemberReplyReducer,
  updateCommunityProMemberReply: updateCommunityProMemberReplyReducer,
  deleteCommunityProMemberReply: deleteCommunityProMemberReplyReducer,
  fetchCoaches: fetchCoachesReducer,

  createCommunityUasPost: createUasPostReducer,
  updateCommunityUasPost: updateCommunityUasPostReducer,
  deleteCommunityUasPost: deleteCommunityUasPostReducer,
  createCommunityUasComment: createCommunityUasCommentReducer,
  updateCommunityUasComment: updateCommunityUasCommentReducer,
  deleteCommunityUasComment: deleteCommunityUasCommentReducer,
  createCommunityUasReply: createCommunityUasReplyReducer,
  updateCommunityUasReply: updateCommunityUasReplyReducer,
  deleteCommunityUasReply: deleteCommunityUasReplyReducer,

  createTpcCourse: createTpcCourseReducer,
  updateTpcCourse: updateTpcCourseReducer,
  fetchTpcCourses: fetchTpcCoursesReducer,
  deleteTpcCourse: deleteTpcCourseReducer,
  createTpcCourseCategory: createTpcCourseCategoryReducer,
  createTpcCourseLessons: createTpcCourseLessonsReducer,
  updateTpcCourseCategoryOrder: updateTpcCourseCategoryOrderReducer,
  updateTpcCourseCategory: updateTpcCourseCategoryReducer,
  updateTpcCourseLessons: updateTpcCourseLessonsReducer,
  deleteTpcCourseLessons: deleteTpcCourseLessonsReducer,
  deleteTpcCourseCategory: deleteTpcCourseCategoryReducer,
  createBundleCourses: createBundleCoursesReducer,
  fetchBundleCourses:fetchBundleCoursesReducer,

  updateMarkedCompleteLesson : updateMarkedCompleteLessonReducer,
  fetchMarkedCompleteLesson : fetchMarkedCompleteLessonReducer,

  fetchAppHourUsedLogs: fetchAppHourUsedLogsReducer,
  fetchAppLog: fetchAppLogReducer,
  fetchPremiumHourLogs:fetchPremiumHourLogsReducer,
  sendEmailApplicationHourUsed: sendEmailApplicationHourUsedReducer,
  sendEmailNotification: sendEmailApplicationHourUsedReducer
});

export default rootReducer;
