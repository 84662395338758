import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import moment from "moment-timezone";
import { connect, useDispatch } from "react-redux";
import {
    Card,
    CardBody,
    CardTitle,
    CardHeader,
    Container,
    Button,
    Row,
    Col,
    CardImg,
    Media,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input
} from "reactstrap";
import { forEachChild } from "typescript";

const CourseItemList = (props) => {

    let lessonNumber = 0;
    
    const [item,setItem ] = useState(props.item)
    
    item.category.forEach(element => {
        lessonNumber = lessonNumber +  element.lessons.length
    });
    const [lessonLength, setLessonNumber] = useState(lessonNumber)

    const handleStartCourse = (e, item) => {
        props.courseCallback(item)
    }
   
return (
    <Col xl="12" lg="12" sm="12">
   
    <Card className="shadow rounded mb-5" outline>
        <CardBody className="p-0 ">
            <Row>
                <Col lg="6" md="12" sm="12">
                    {item.courseThumbnail != "" ? 
                    
                        <CardImg
                            alt="..."
                            src={`https://storage.googleapis.com/tpcdashboard.appspot.com/img-tpc-courses/` + item.courseThumbnail+``}
                            
                            top
                            style={{height : "16em", objectFit: "fill", width:"100%"}}
                        />
                        
                        :

                        <CardImg
                            alt="..."
                        
                            src={require("assets/img/brand/no-image.jpg")}
                            top
                            style={{height : "16em", objectFit: "fill", width:"100%"}}
                        />
                    }
                </Col>
                <Col lg="6" md="12" sm="12" className=" d-flex flex-column flex-wrap">
                    <div className="mb-auto py-3 px-2">
                        <h3 >{item.courseTitle}</h3>
                        <small>
                            { 
                                item.courseDescription.length > 99 ?
                                <>
                                    {item.courseDescription.slice(0, 85) } <span className="text-primary " role="button" onClick={e => handleStartCourse(e, item)}> ...read more </span>
                                </>
                                : item.courseDescription
                            }
                            
                            
                        </small>
                    </div>
                    <div className="mb-1  px-2">
                        <Button
                            color="default"
                            className="rounded-pill"
                            onClick={e => handleStartCourse(e, item)}
                            >
                            <i className="fas fa-play"></i>&nbsp;&nbsp;Start Lesson
                        </Button>
                        
                        <div className="mt-2 mb-1 px-1">
                            <small className="mr-3">{item.category !== undefined ? lessonLength : 0} Lessons</small>
                            <small className="mr-3">By: {item.instructorName} </small>
                        </div>
                        
                    </div>
                    
                </Col>
            </Row>
        </CardBody>
    </Card>
   

    </Col>
)


}

const mapStateToProps = (state) => {
  return {
    userDetails: state.loginAuth.userDetails,
  };
};

export default connect(mapStateToProps)(CourseItemList);